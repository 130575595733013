import { LoadingButton } from "@mui/lab";
import { Alert, Button } from "@mui/material";
import { useFormContext } from "react-hook-form";
import Ternary from "src/components/ternary";

const ErrorBanner = ({ loading, EmailReVerify, isEmailNotVerified }) => {
  const {
    formState: { errors },
  } = useFormContext();
  return (
    <>
      {!!errors.afterSubmit && (
        <Alert
          severity="error"
          action={
            <>
              <Ternary
                when={isEmailNotVerified}
                then={
                  <>
                    <LoadingButton
                      type="submit"
                      loading={loading}
                      size="small"
                      onClick={EmailReVerify}
                      style={{ textTransform: "none" }}
                    >
                      Resend eMail
                    </LoadingButton>
                  </>
                }
              />
            </>
          }
        >
          {errors.afterSubmit.message}
        </Alert>
      )}
    </>
  );
};

export default ErrorBanner;
