const LANGS = [
  {
    label: "English",
    value: "en",
    icon: "/assets/icons/flags/ic_flag_en.svg",
  },
  // {
  //   label: "Spanish",
  //   value: "es",
  //   icon: "/assets/icons/flags/ic_flag_sp.svg",
  // },
  // {
  //   label: "Portuguese",
  //   value: "pt",
  //   icon: "/assets/icons/flags/ic_flag_pt.svg",
  // },
  {
    label: "German",
    value: "de",
    icon: "/assets/icons/flags/ic_flag_de.svg",
  },
  // {
  //   label: "Japanese",
  //   value: "ja",
  //   icon: "/assets/icons/flags/ic_flag_jp.svg",
  // },
  // {
  //   label: "Italian",
  //   value: "it",
  //   icon: "/assets/icons/flags/ic_flag_ity.svg",
  // },
  // {
  //   label: "Chinese",
  //   value: "zh",
  //   icon: "/assets/icons/flags/ic_flag_cn.svg",
  // },
  // {
  //   label: "Arabic",
  //   value: "ar",
  //   icon: "/assets/icons/flags/ic_flag_ar.svg",
  // },
  // {
  //   label: "Russian",
  //   value: "ru",
  //   icon: "/assets/icons/flags/ic_flag_ru.svg",
  // },
  // {
  //   label: "Polish",
  //   value: "pl",
  //   icon: "/assets/icons/flags/ic_flag_pl.svg",
  // },
  // {
  //   label: "Turkish",
  //   value: "tr",
  //   icon: "/assets/icons/flags/ic_flag_tr.svg",
  // },
  // {
  //   label: "French",
  //   value: "fr",
  //   icon: "/assets/icons/flags/ic_flag_fr.svg",
  // },
];

export default LANGS;
